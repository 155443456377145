import React, { useEffect, useState } from 'react';
import '../css/sendmail.css';
import CancelIcon from '@mui/icons-material/Cancel';

const SendMail = props => {
  const [message, setmessage] = useState();
  const sendMail = () => {
    const request = fetch();
  };
  useEffect(() => {});

  return (
    <>
      <div className="container">
        <div className="email-container">
          <div className="email-header pb-3">
            <div>
              <h1 className="h5 mb-0 px-3 font-weight-bold">{props.title}</h1>
            </div>
            <div>
              <button
                className="btn btn-light btn-sm"
                onClick={() => props.setOpen(false)}
              >
                <CancelIcon style={{ color: 'gray' }} />
              </button>
            </div>
          </div>
          <div className="email-body">
            <div className="mb-3">
              <label htmlFor="toEmail" className="form-label">
                To:
              </label>
              <input
                type="email"
                className="form-control"
                id="toEmail"
                disabled
                placeholder="sponosr@gmail.com"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="form-label">
                Subject:
              </label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder="Thank you for your support"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message:
              </label>
              <textarea
                className="form-control"
                id="message"
                rows={8}
                placeholder={
                  "Dear [Sponsor],\n\nThank you so much for your support! Your help means a lot to me as I continue my studies. I'm excited about the opportunities ahead, and I couldn't do it without you.\n\nBest regards,\n[Your Name]"
                }
              />
            </div>
          </div>
          <div className="email-footer mx-3">
            <button
              className="btn"
              style={{ backgroundColor: '#77000D', color: 'white' }}
            >
              Clear message
            </button>
            <button
              className="btn"
              style={{ backgroundColor: '#77000D', color: 'white' }}
            >
              Send message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMail;
