const initialTabState = {
    activeTab: 'home', 
  };
  
  const tabReducer = (state = initialTabState, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_TAB':
        return {
          ...state,
          activeTab: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default tabReducer;
  