// Functionality of this code : This page contains the Student page section
// Input : None
// Output : Student page
// Written by: Initial team VK, KR
import './css/studentRequest.css';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import StudentApplication from './components/StudentApplication';
import PopCheckStatus from './components/PopCheckStatus';
import CardMedia from '@material-ui/core/CardMedia';
import maskgroup1 from './assets/new/mask-group-1.png';
import maskgroup2 from './assets/new/mask-group-2.png';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  formatTab: {
    textTransform: 'none',
  },

  maskGroup: {
    height: '180px',
    left: 0,
    objectFit: 'cover',
    position: 'absolute',
    '@media (max-width: 900px)': {
      height: 0,
    },
  },

  divCheck: {
    alignItems: 'flex-end',
    backgroundColor: '#232323',
    borderRadius: '5px',
    display: 'flex',
    height: '40px',
    justifyContent: 'flex-end',
    minWidth: '150px',
    padding: '8px 25px',
  },

  divCheckStatus: {
    color: '#ffffff',
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: '12px',
    minHeight: '18px',
    minWidth: '97px',
    whiteSpace: 'nowrap',
  },

  cBanner: {
    '@media (max-width: 1920px)': {
      height: '250px',
    },
    width: '100%',
    height: '200px',
    marginTop: '97px',
  },

  cBannerLeft: {
    width: '50%',
    float: 'left',
    backgroundImage: { maskgroup1 },
  },

  cBannerRight: {
    width: '50%',
    float: 'left',
    backgroundImage: { maskgroup2 },
  },

  cBannerImg: {
    position: 'absolute',
    top: '0',
  },

  maskGroup1: {
    marginTop: '110px',
    right: '0',
    objectFit: 'cover',
    position: 'absolute',
    width: '90px',
    '@media (max-width: 767px)': {
      width: '50px',
    },
  },

  cBannerH1: {
    '@media (max-width: 762px)': {
      top: '53px',
      left: 0,
      fontSize: '18px',
    },
    padding: '2% 7% 0% 7%',
    backgroundColor: 'transparent',
    color: '#ed0a3f',
    fontStyle: 'normal',
    fontWeight: '700',
    height: 'auto',
    left: '30%',
    letterSpacing: '0.00px',
    lineHeight: '48px',
    position: 'absolute',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    width: 'auto',
    textAlign: 'center',
  },

  cBannerDetail: {
    backgroundColor: 'transparent',
    padding: '8% 10% 0% 14%',
    letterSpacing: '0.20px',
    lineHeight: '30px',
    position: 'absolute',
    textAlign: 'center',
    '@media (max-width: 900px)': {
      lineHeight: 1.5,
    },
  },

  maskGroupup: {
    right: '0',
    objectFit: 'cover',
    position: 'absolute',
    top: '0',
    width: '100px',
  },

  customcontainer: {
    marginTop: '60px',
    maxWidth: '1365px',
  },

  cBannerH1: {
    color: '#F4113D',
    fontWeight: 700,
    lineHeight: 'normal',
    textAlign: 'center',
    fontSize: '26px',
    marginTop: '145px',
    marginBottom: '45px',
    '@media (max-width: 767px)': {
      fontSize: '22px',
      marginBottom: '30px',
    },
  },

  cBannerDetail: {
    lineHeight: '30px',
    textAlign: 'center',
    '@media (max-width: 900px)': {
      lineHeight: 1.5,
    },
  },

  paratext: {
    margin: '0 0 30px 0',
    lineHeight: '24px',
    fontSize: '16px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },

  cBannerIndex: {
    position: 'relative',
    zIndex: '1',
  },

  cBanner: {
    backgroundColor: '#f2f2f2',
    padding: '50px',
    width: '100%',
    '@media (max-width: 900px)': {
      padding: '50px 0',
    },
    '@media (max-width: 767px)': {
      padding: '30px 0',
      marginTop: '-85px',
    },
  },

  cApplication: {
    padding: '2% 12% 2% 12%',
  },

  maskGroupdown: {
    left: '0',
    objectFit: 'cover',
    position: 'absolute',
    bottom: '0',
    width: '100px',
  },

  maskGroup2: {
    left: '0',
    bottom: '0',
    objectFit: 'cover',
    position: 'absolute',
    width: '150px',
    '@media (max-width: 767px)': {
      width: '100px',
    },
  },

  btn: {
    backgroundColor: '#232323',
    color: '#f2f2f2',
    marginBottom: 8,
    '&:hover': {
      background: '#232323',
    },
    '@media (max-width: 767px)': {
      width: '100px',
      marginLeft: '-35px',
    },
  },

  cStatus: {
    margin: 'auto',
    width: '9%',
    paddingBottom: '20px',
  },
}));

//Main Page for all students that has tabs to application and table page.
export default function StudentPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  useEffect(() => {}, []);
  return (
    <div className="mid-bg">
      <div className="submit-section">
        <div className="container">
          <div className="main-box">
            <div className="row">
              <div className="title-heading">
                <h3 className="title-style">
                  Submit A Request And Make A Pledge
                </h3>
                <p>
                  Raise a request and with every request make a pledge to
                  volunteer in the community or sponsor others in future! You
                  are a student today and can be a sponsor tomorrow!
                </p>
              </div>
            </div>
            <StudentApplication />
              {/* ====================Get Started BTN =============== */}
              <div className="row">
                <div className="col-md-12">
                  <PopCheckStatus open={open} setOpen={setOpen} />
                  <div className="get-start">
                    <a href="javascript:void(0);" 
                      onClick={() => {
                        setOpen(true);
                      }}
                      className="get-start-btn"
                    >
                      Check Status
                      <span>
                        {' '}
                        <svg
                          width={25}
                          height={16}
                          viewBox="0 0 25 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
