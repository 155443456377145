// Functionality of this code : This page contains the Header section
// Input : None
// Output : Home Page -> Header
// Written by: Initial team VK, KR

import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Menu,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPreferences, resetUser } from '../store/actions/userActions';
import { setActiveTab } from '../store/actions/tabaction';
import '../css/button.css';
import student from '../assets/user.png';
import sponsor from '../assets/sponsor.png';
import organization from '../assets/org.jpg';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#fff',
    height: '90px',
  },
  logo: {
    fontFamily: 'Calibri',
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  menuButton: {
    fontFamily: 'Calibri',
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    maxWidth: '1365px',
    margin: '0px auto',
    width: '100%',
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  logoHead: {
    width: '100px',
    height: 'auto',
    left: '0px',
    top: '5px',
    position: 'absolute',
    '@media (max-width: 767px)': {
      width: '70px',
      left: '5px',
    },
  },
  iconView: {
    left: '60px',
    marginTop: '25px',
    color: '#ed0a3f',
    backgroundColor: '#ffdcd3',
    borderRadius: 10,
    padding: 4,
    '@media (max-width: 991px)': {
      fontSize: '35px',
    },
  },
  toolbarMob: {
    '@media (max-width: 991px)': {
      justifyContent: 'flex-end',
      paddingLeft: '68%',
    },
  },
  headButton: {
    color: '#4b4b4b',
    fontFamily: 'Railey,sans-serif',
    fontSize: '15px',
    margin: '33px 30px',
    '&:hover': {
      // backgroundColor: '#fff !important',
    },
  },

  headButtonMobo: {
    color: '#4b4b4b',
    fontFamily: 'Railey,sans-serif',
    fontSize: '15px',
    padding: '8px 0px',
    borderRadius: '5px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '10px',
    fontSize: '15px',
  },
  headLoginButton: {
    backgroundColor: '#232323',
    color: 'white',
    fontSize: '16px',
    fontWeight: '700',
    padding: '6px 27px',
    marginLeft: '30px',
  },
  // Define your activeButton style here
  activeButton: {
    color: 'white',
    background: '#77000D !important',
  },
  activeMoboButton: {
    color: '#77000D',
  },
  profileWrapper: {
    marginLeft: '20px',
    '@media (max-width: 991px)': {
      marginLeft: '10px',
    },
  },
  avatar: {
    marginRight: '10px',
  },
  profileName: {
    fontSize: '16px',
    fontWeight: '600',
  },
}));

//Header responsive
export default function Header({
  isAuthenticated,
  logOut,
  logoSrc,
  setPage,
  firebase,
  setNewOpen,
  setContact,
  role,
  setRole,
  page,
}) {
  const {
    header,
    toolbar,
    drawerContainer,
    logoHead,
    iconView,
    headButton,
    headButtonMobo,
    headLoginButton,
    toolbarMob,
    activeButton,
    activeMoboButton,
    profileWrapper,
    avatar,
    profileName,
  } = useStyles();

  const dispatch = useDispatch();
  const username = useSelector(state => state.user.name);
  const activeTab = useSelector(state => state.tab.activeTab);

  // State for active tab fun
  // const [activeTab, setActiveTab] = useState('home');

  //set state for mobile & desktop
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  // State for dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    const handleAuthStateChanged = async user => {
      if (user) {
        try {
          const tokenResult = await firebase
            .auth()
            .currentUser.getIdTokenResult();
          const token = tokenResult.token;

          const response = await fetch(
            `${process.env.REACT_APP_PROXY}/user/pref`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                auth: token,
              },
            }
          );
          const data = await response.json();

          if (data.success) {
            dispatch(fetchUserPreferences(data.message[0]));
          }
        } catch (error) {
          console.error('Error fetching user preferences:', error);
        }
      }
    };

    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(handleAuthStateChanged);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Update activeTab whenever isAuthenticated changes
    dispatch(setActiveTab(isAuthenticated ? 'dashboard' : 'home'));
  }, [isAuthenticated]);

  //display desktop
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  //menu popup for small device
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState(prevState => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState(prevState => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={toolbarMob}>
        {isAuthenticated && username && (
          <span>
            <Button className={profileWrapper} onClick={handleMenuOpen}>
              <Avatar
                alt="Profile Picture"
                src={
                  role === 'student'
                    ? student
                    : role === 'sponsor'
                    ? sponsor
                    : organization
                }
                className={avatar}
                style={{ border: '2px black solid' }}
              />
              <span className={profileName}>{username}</span>
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  setNewOpen(true);
                  handleMenuClose();
                }}
              >
                My Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logOut();
                  dispatch(resetUser());
                  handleMenuClose();
                  setRole('');
                  setPage('home');
                }}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </span>
        )}
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
          }}
          className={iconView}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        <div>{femmecubatorLogo}</div>
      </Toolbar>
    );
  };

  const handleSetPage = page => {
    setPage(page);
    dispatch(setActiveTab(page));
  };

  //menu popup for desktop
  const getDrawerChoices = () => {
    return (
      <>
        {!isAuthenticated && (
          <div
            size="large"
            onClick={() => handleSetPage('home')}
            className={`${headButtonMobo} ${
              activeTab === 'home' ? activeButton : ''
            }`}
          >
            HOME
          </div>
        )}
        <div
          className={`${headButtonMobo} ${
            activeTab === 'page' || activeTab === 'about' ? activeButton : ''
          }`}
          onClick={() => handleSetPage(isAuthenticated ? 'page' : 'about')}
          size="large"
        >
          {role !== 'admin' && isAuthenticated && username
            ? role === 'student'
              ? 'APPLICATION'
              : 'REQUESTS'
            : !isAuthenticated && 'ABOUT US'}
        </div>

        {role !== 'admin' && isAuthenticated && username && (
          <div
            size="large"
            className={`${headButtonMobo} ${
              activeTab === 'dashboard' ? activeButton : ''
            }`}
            onClick={() => handleSetPage('dashboard')}
          >
            {`${role.toUpperCase()} DASHBOARD`}
          </div>
        )}
        {!isAuthenticated && (
          <div
            className={`${headButtonMobo} ${
              activeTab === 'howitworks' ? activeButton : ''
            }`}
            size="large"
            onClick={() => handleSetPage('howitworks')}
          >
            HOW IT WORKS
          </div>
        )}
        {!isAuthenticated && (
          <div
            className={headButtonMobo}
            size="large"
            onClick={() => setContact(true)}
          >
            CONTACT
          </div>
        )}

        {/* <Link
          style={{ color: '#2998D1', minHeight: '18px' }}
          onClick={() => {
            if (isAuthenticated) {
              logOut();
              setPage('home');
            } else setPage('auth');
          }}
          href="#"
        >
          <MenuItem>{isAuthenticated ? 'SIGN OUT' : 'SIGN IN'} </MenuItem>
        </Link> */}

        {role !== 'admin' && !isAuthenticated && (
          <div
            onClick={() => handleSetPage('auth')}
            href="#"
            className={`${headButtonMobo} ${
              activeTab === 'auth' ? activeButton : ''
            }`}
          >
            SIGN IN
          </div>
        )}

        {role !== 'admin' && isAuthenticated && !username && (
          <Button
            onClick={() => {
              logOut();
              dispatch(resetUser());
              handleMenuClose();
              setRole('');
              setPage('home');
            }}
            href="#"
            className={headLoginButton}
          >
            SIGN OUT
          </Button>
        )}

        {role && role.toUpperCase && isAuthenticated && !username && (
          <button className="button mb-2">{`${role.toUpperCase()} PREFERENCE PAGE`}</button>
        )}
      </>
    );
  };

  const femmecubatorLogo = (
    <Avatar alt="Logo" variant="square" src={logoSrc} className={logoHead} />
  );

  const getMenuButtons = () => {
    return (
      <div>
        {!isAuthenticated && (
          <Button
            className={`${headButton} ${
              activeTab === 'home' ? activeButton : ''
            }`}
            color="inherit"
            size="large"
            onClick={() => handleSetPage('home')}
          >
            HOME
          </Button>
        )}

        {role !== 'admin' && isAuthenticated && username && (
          <Button
            className={`${headButton} ${
              activeTab === 'dashboard' ? activeButton : ''
            }`}
            color="inherit"
            size="large"
            onClick={() => handleSetPage('dashboard')}
          >
            {`${role} DASHBOARD`}
          </Button>
        )}

        {role !== 'admin' ? (
          <Button
            color="inherit"
            className={`${headButton} ${
              activeTab === 'page' || activeTab === 'about' ? activeButton : ''
            }`}
            onClick={() => handleSetPage(isAuthenticated ? 'page' : 'about')}
            size="large"
          >
            {role !== 'admin' && isAuthenticated && username
              ? role === 'student'
                ? 'APPLICATION'
                : 'REQUESTS'
              : !isAuthenticated && 'ABOUT US'}
          </Button>
        ) : (
          ''
        )}

        {!isAuthenticated && (
          <Button
            className={`${headButton} ${
              activeTab === 'howitworks' ? activeButton : ''
            }`}
            color="inherit"
            size="large"
            onClick={() => handleSetPage('howitworks')}
          >
            HOW IT WORKS
          </Button>
        )}
        {!isAuthenticated && (
          <Button
            className={headButton}
            color="inherit"
            size="large"
            onClick={() => setContact(true)}
          >
            CONTACT
          </Button>
        )}

        {isAuthenticated && username && (
          <span>
            <Button className={profileWrapper} onClick={handleMenuOpen}>
              <Avatar
                alt="Profile Picture"
                src={
                  role === 'student'
                    ? student
                    : role === 'sponsor'
                    ? sponsor
                    : organization
                }
                className={avatar}
                style={{ border: '2px black solid' }}
              />
              <span className={profileName}>{username}</span>
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  setNewOpen(true);
                  handleMenuClose();
                }}
              >
                My Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logOut();
                  dispatch(resetUser());
                  handleMenuClose();
                  setRole('');
                  setPage('home');
                }}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </span>
        )}

        {role !== 'admin' && !isAuthenticated && (
          <Button
            onClick={() => handleSetPage('auth')}
            href="#"
            className={`${headLoginButton} ${
              activeTab === 'auth' ? activeButton : ''
            }`}
          >
            SIGN IN
          </Button>
        )}
        {role !== 'admin' && isAuthenticated && !username && (
          <Button
            onClick={() => {
              logOut();
              dispatch(resetUser());
              handleMenuClose();
              setRole('');
              setPage('home');
            }}
            href="#"
            className={headLoginButton}
          >
            SIGN OUT
          </Button>
        )}

        {role && role.toUpperCase && isAuthenticated && !username && (
          <button className="button mb-2">{`${role.toUpperCase()} PREFERENCE PAGE`}</button>
        )}
      </div>
    );
  };

  //render the heared as per device size
  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
