import React from 'react';
import { CardsTooltip } from '../utils/MyTooltip';
import notification from '../assets/notification-bell.gif';
import donate from '../assets/donatenow.gif';

const Cards = props => {
  return (
    <>
      {CardsTooltip(
        props.message,
        props.position,
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4 ">
          <div
            className={`main_card`}
            onClick={props.onClick}
            style={{ position: 'relative' }}
          >
            {props.title === 'Requests Received' && props.newRequest > 0 && (
              <img
                src={notification}
                alt="Top right GIF"
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  width: '40px',
                  height: '40px',
                }}
              />
            )}
            <img src={ props.number==='Donate Now' && props.newRequest ? donate : props.img} alt="imgIcon" width={60} />
            <div>
              <h4>
                <b>{props.number}</b>
              </h4>
              <p>{props.title}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cards;
