import Tooltip from '@mui/material/Tooltip';
import { tooltipMessages } from './tooltipMessages';
import { styled } from '@mui/material/styles';

// Utility function to create a Tooltip
export const createTooltip = (messageKey, position, children) => {
  const message = tooltipMessages[messageKey];
  return (
    <Tooltip
      title={message}
      placement={position} 
      arrow 
    >
      {children}
    </Tooltip>
  );
};

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    borderRadius: 8,
    padding: theme.spacing(1),
  },
}));

export const timelineTooltip = (
  messageKey,
  dynamicData = {},
  activeSteps,
  index,
  position = 'bottom',
  children
) => {
  const TimeLinetooltipMessage = {
    step1: `Your application for ${dynamicData.needType} has been submitted on ${dynamicData.requestDate}.`,
    step2: `${
      dynamicData.matchDate
        ? `You were matched with a sponsor on ${dynamicData.matchDate}`
        : 'You don’t have any matches yet'
    }`,
    step3: `Your ${dynamicData.needType} application ${
      dynamicData.fulfillDate
        ? `was fulfilled on ${dynamicData.fulfillDate}`
        : 'has not been fulfilled yet'
    } .`,
    step4: `${
      activeSteps[index] === 2
        ? 'Click here and Write a thank you note to your sponsor for their support.'
        : 'You can send a thank you note once the application is fulfilled.'
    }`,
  };

  const message = TimeLinetooltipMessage[messageKey];
  return (
    <CustomTooltip title={<>{message}</>} placement={position} arrow>
      {children}
    </CustomTooltip>
  );
};



// Tooltip for cards 
export const CardsTooltip = (
  messageKey,
  position,
  children
) => {


  const message = messageKey;
  return (
    <CustomTooltip title={<>{message}</>} placement={position} arrow>
      {children}
    </CustomTooltip>
  );
};

