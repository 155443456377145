import firebase from "firebase";

const checkAuth = async () => {
  try {
    const idToken = await firebase.auth().currentUser.getIdToken();
    return idToken;
  } catch (error) {
    console.error('Error getting auth token:', error);
    return null;
  }
};

export default checkAuth;