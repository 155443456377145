const initialState = {
  name: '',
  preferences: {},
  application: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'FETCH_USER_PREFERENCES':
      return {
        ...state,
        preferences: action.payload,
        name: action.payload.name,
      };
      case 'SET_USER_APPLICATION_DETAILS':
        return {
          ...state, 
          application : action.payload
        }
    case 'RESET_USER':
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
