// Functionality of this code : This page contains the widgets on the Home Page
// Input : None
// Output : Home Page
// Written by: Initial team VK, KR

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import FeaturedPost from './components/FeaturedPost';
import MainFeaturedPost from './components/MainFeaturedPost';
import poster3 from './assets/poster3.jpg';
import maskgroup1 from './assets/new/cloudtop.png';
import maskgroup2 from './assets/new/cloudbottom.png';
import poster4 from './assets/poster4.jpg';
import poster5 from './assets/poster5.png';
import cardImg1 from './assets/card-img1.png';
import cardImg2 from './assets/card-img2.png';
import cardImg3 from './assets/card-img3.png';
import Platform from './Platform';
import FeaturedPosts from './components/FeaturedPosts';
import VideoSection from './components/VideoSection';
import './css/style.css';
import { Assignment, Schedule, PeopleAlt } from '@material-ui/icons';
import ActivityCards from './utils/ActivityCards';
import request from './assets/newui/request.png';
import hours from './assets/newui/hours.png';
import sponsors from './assets/newui/sponsors.webp';

/* The home page */
const useStyles = makeStyles(
  theme => ({
    mainGrid: {
      marginTop: theme.spacing(3),
    },
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },

    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    toolbar: {
      flexWrap: 'wrap',
    },

    toolbarTitle: {
      flexGrow: 1,
    },

    link: {
      margin: theme.spacing(1, 1.5),
    },

    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },

    cardHeader: {
      fontSize: '18px !important',
      color: '#2998D1',
      padding: '30px 40px',
    },

    cardContent: {
      padding: '0px 40px',
      marginBottom: '30px',
    },

    maskGroupup: {
      right: '0',
      objectFit: 'cover',
      position: 'absolute',
      top: '0',
      width: '100px',
    },

    maskGroupdown: {
      left: '0',
      objectFit: 'cover',
      position: 'absolute',
      bottom: '0',
      width: '100px',
    },

    cBanner: {
      backgroundColor: '#f2f2f2',
      padding: '50px 0',
      width: '100%',
      '@media (max-width: 900px)': {
        padding: '50px 0',
      },
      '@media (max-width: 767px)': {
        padding: '30px 0',
      },
    },

    cBannerImg: {
      position: 'absolute',
      top: '0',
    },

    cBannerH1: {
      color: '#F4113D',
      fontWeight: 700,
      lineHeight: 'normal',
      textAlign: 'center',
      fontSize: '26px',
      fontFamily: 'Calibri',
      marginTop: '0',
      marginBottom: '45px',
      '@media (max-width: 767px)': {
        fontSize: '22px',
        marginBottom: '30px',
      },
    },

    cBannerDetail: {
      lineHeight: '30px',
      textAlign: 'center',
      '@media (max-width: 900px)': {
        lineHeight: 1.5,
      },
    },

    paratext: {
      margin: '0 0 30px 0',
      lineHeight: '24px',
      fontSize: '16px',
      fontFamily: 'Calibri',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },

    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },

    cBannerIndex: {
      position: 'relative',
      zIndex: '1',
    },

    spacing: {
      padding: '20px 0',
      '@media (max-width: 900px)': {
        padding: '50px 0',
      },
      '@media (max-width: 767px)': {
        padding: '30px 0',
      },
    },

    bgcolor: {
      background: '#f2f2f2',
    },

    threebox: {
      '@media (max-width: 767px)': {
        padding: '15px!important',
      },
    },

    customcontainer: {
      maxWidth: '1365px',
    },

    linepattern: {
      background: 'linear-gradient(90deg, #232323 23.43%, #ffffff 80.81%)',
      backgroundColor: 'transparent',
      height: '4px',
      right: '0px',
      position: 'absolute',
      width: 'calc(100%)',
      // marginTop: '80px',
      '@media (max-width: 900px)': {
        marginTop: '0px',
      },
      '@media (max-width: 767px)': {
        marginTop: '0px',
      },
    },

    linepattern2: {
      background: 'linear-gradient(90deg, #232323 23.43%, #ffffff 80.81%)',
      backgroundColor: 'transparent',
      height: '4px',
      right: '0px',
      transform: 'scaleX(-1)',
      marginTop: '10px',
      position: 'absolute',
      width: 'calc(100%)',
      // marginTop: '80px',
      '@media (max-width: 900px)': {
        marginTop: '0px',
      },
      '@media (max-width: 767px)': {
        marginTop: '0px',
      },
    },

    cardBox: {
      padding: '70px 0',
      width: '100%',
      // marginLeft: '80px',
      '@media (max-width: 767px)': {
        padding: '30px 0',
        width: '118%',
        marginLeft: '-32px',
      },
    },

    cardMedia: {
      marginTop: '-3px',
    },
    cardImgblock: {
      width: 66,
      height: 66,
      borderRadius: '50%',
      background: '#F4113D',
      padding: '15px',
      textAlign: 'center',
      margin: '40px 40px 0px 40px',
    },
  }),
  { index: 1 }
);

// Define content of the cards on the home page
const mainFeaturedPost = {
  title: 'Enabling a Virtuous Cycle',
  description:
    'Sponsor A Student is an educational sponsorship platform dedicated for students pursuing higher education and in need of financial assistance.',
  description2:
    'Students simply raise a request to get the help paying for education-related expenses. With our unique direct to invoice sponsorship model, sponsors can immediately fulfill a student’s request and see the impact of their gift!',
  description3:
    'In return, students commit to paying it back either by volunteering their time or becoming a future sponsor on Sponsor A Student.',
  image: poster3,
};

const featuredPosts = [
  {
    title: 'Story of the week',
    date: Date,
    description:
      'This is a place holder for a success story from the week from the students who benefitted',
    image: poster4,
  },
  {
    title: 'Featured post',
    date: Date,
    description:
      'This is a place holder for advertising from educational or commercial institutions',
    image: poster5,
  },
];

//Home Page starts here
export default function HomePage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [state, setState] = React.useState({
    requests: [],
    pledges: [],
  });
  const [volunteer_hours, setVolunteer_hours] = React.useState(0);
  const [project_hours, setProject_hours] = React.useState(0);
  const [requests_pledged, setRequests_pledged] = React.useState(0);
  const [requests_fullfill, setRequests_fullfill] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  var count = 0;
  const classes = useStyles();

  // Get
  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + '/stats/application')
      .then(response => response.json())
      .then(data => {
        if (data.success === false) {
          setVolunteer_hours(0);
          setProject_hours(0);
          setRequests_pledged(0);
          setRequests_fullfill(0);
          setTotal(0);
        } else {
          for (var value of data.message) {
            if (value.status !== 'Active') {
              count++;
            }
            setVolunteer_hours(prevHours => prevHours + value.volunteer_hours);
            setProject_hours(prevHours => prevHours + value.mini_project_hours);
            setRequests_fullfill(count);
            setRequests_pledged(
              prevHours => prevHours + value.requests_willing_to_sponsor
            );
          }
          setTotal(data.message.length);
          setState(prevState => {
            let requests = data.message;
            if (data.success === false) requests = [];
            return { ...prevState, requests };
          });
        }
      })
      .catch(err => {
        throw new Error('An error occurred while fetching data',err);
      });
  }, []);

  //static data for number of Requests, Volunteers and Pledges
  // Assuming you have icons component

  const tiers = [
    {
      title: 'Requests',
      description: [total + ' Raised', requests_fullfill + ' Fullfilled'],
      cardImg: cardImg1,
      icon: request,
    },
    {
      title: 'Volunteers hours',
      description: [
        volunteer_hours + ' Pledged',
        project_hours + ' Volunteered',
      ],
      cardImg: cardImg2,
      icon: hours,
    },
    {
      title: 'Sponsorship pledges',
      description: [
        requests_pledged + ' Commited',
        requests_fullfill + ' Supported',
      ],
      cardImg: cardImg3,
      icon: sponsors,
    },
  ];

  return (
    <>
      <CssBaseline />
      <main>
        {/* main featured */}
        <MainFeaturedPost post={mainFeaturedPost} setPage={props.setPage} />
        {/* main featured end*/}

        {/*-------------------  The platform page -------------------------*/}
        {/* <Grid container style={{ position: 'relative' }}>
          <CardMedia
            component="img"
            className={classes.maskGroupup}
            image={maskgroup1}
            title="Live from space album cover"
          />
          <div className={classes.cBanner}>
            <Container className={classes.customcontainer}>
              <div className={classes.cBannerIndex}>
                <h1 className={classes.cBannerH1}>The Platform</h1>
                <div className={classes.cBannerDetail}>
                  <p className={classes.paratext}>
                    Aligns with goal number 4 of the United Nations 2030
                    Sustainable Development Agenda to <br /> “Ensure inclusive
                    and equitable quality education and promote lifelong
                    learning opportunities for all”!
                  </p>
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: '#232323',
                      color: '#ffffff',
                      border: '1px solid #232323',
                    }}
                    onClick={() => {
                      props.setPage('about');
                    }}
                  >
                    {' '}
                    ABOUT US
                  </Button>
                </div>
              </div>

              <CardMedia
                component="img"
                className={classes.maskGroupdown}
                image={maskgroup2}
                title="Live from space album cover"
              />
            </Container>
          </div>
        </Grid> */}
        <Platform />
        {/* ======================= The platform page end ==================*/}

        {/* ------------------------ featured posts -------------------------*/}
        {/* <Container
          className={classes.customcontainer}
          style={{ padding: '0px 32px' }}
        > */}
        {/* <Grid container spacing={8} className={classes.spacing}>
            <div className={classes.linepattern2}></div>
            {featuredPosts.map(post => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid> */}
        <FeaturedPosts />

        {/* </Container> */}
        {/* ========================= featured posts end ====================*/}

        {/* <div className={classes.bgcolor}>
          <Container
            className={classes.customcontainer}
            style={{ padding: '0px 80px' }}
          >
            <Grid
              container
              spacing={8}
              alignItems="flex-end"
              className={classes.spacing}
            >
              {tiers.map(tier => (
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  className={classes.threebox}
                >
                  <Card>
                    <div className={classes.cardImgblock}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={tier.cardImg}
                        title="Image title"
                        component="img"
                      />
                    </div>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'left' }}
                      subheaderTypographyProps={{ align: 'left' }}
                      action={tier.title === 'Pro' ? <StarIcon /> : null}
                      className={classes.cardHeader}
                    />
                    <CardContent className={classes.cardContent}>
                      <ul>
                        {tier.description.map(line => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="left"
                            key={line}
                            style={{ fontSize: '16px' }}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div> */}

        <section className="home-services py-5" id="services">
          <div className="container py-md-5 py-4">
            {/* <div
              className="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4"
              style={{ maxWidth: '600px' }}
            >
              <h3 className="title-style mb-2">
                Services Offered to Our Customers
              </h3>
              <p>
                Explore the range of services we provide to empower students and
                sponsors.
              </p>
            </div> */}
            <div className="mb-5" style={{ marginTop: '-120px' }}>
              <VideoSection setPage={props.setPage} />
            </div>

            <div className="row">
              {tiers.map((tier, index) => {
                return (
                  // <div className="col-lg-4 col-md-6 mb-3 text-center" key={index}>
                  //   <div className="box-wrap border">
                  //     <div className="icon">
                  //     <i className="fas  blue-clr-bg"> {tier.icon}</i>

                  //     </div>
                  //     <h4 className="number">{tier.number}</h4>
                  //     <h4>{tier.title}</h4>
                  //     <p>{tier.subheader}</p>
                  //     <ul>
                  //       {tier.description.map((line, index) => (
                  //         <li key={index}>{line}</li>
                  //       ))}
                  //     </ul>
                  //     <a href="#read" className="read">
                  //       Read more
                  //     </a>
                  //   </div>
                  // </div>

                  <div
                    className="col-lg-4 col-md-6 my-cards mb-3 text-center"
                    key={index}
                    style={{ marginTop: '110px' }}
                  >
                    <ActivityCards
                      title={tier.title}
                      subheader={tier.subheader}
                      des={tier.description}
                      icon={tier.icon}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
