// Functionality of this code : This is where the user to lead to fill preferences on first time logging in
// Input : None
// Output : Profile/Preference Settings
// Written by: Initial team VK, KR
import '../css/modal.css';
import React, { useRef } from 'react';
import {} from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FlatButton from '@material-ui/core/Button';
import { Preferences } from './StudentDonorProfile';
import { UniversityProfile } from './UniversityProfile';
import { makeStyles } from '@material-ui/core';

/* this is the settings page pop out when profile/pref is clicked */
export default function Settings(props) {
  const childRef = useRef();
  const role = props.role;

  const handleClose = () => {
    props.setOpen(false);
  };

  const useStyles = makeStyles(() => ({
    profileButton: {
      backgroundColor: '#232323',
      color: '#f2f2f2',
      '&:hover': {
        background: '#232323',
      },
    },
  }));

  const { profileButton } = useStyles();
  return (
    <div>
      <Dialog
        fullWidth
        modal="false"
        open={props.open}
        onClose={handleClose}
        color="primary"
        PaperProps={{
          style: {
            // maxWidth: 880,
            borderRadius: '16px',
            padding: '20px',
            boxShadow: '0px 4px 16px 0px #00000030',
          },
        }}
      >
        <h6
          style={{
            padding: '24px',
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '21.09px',
            fontFamily: 'Roboto',
          }}
        >
          {role === 'student'
            ? 'Student Profile'
            : role === 'sponsor'
            ? 'Sponsor Preferences'
            : 'Preferences'}
        </h6>
        <DialogContent>
          <form noValidate autoComplete="off">
            {role !== 'organization' ? (
              <Preferences
                setOpen={props.setOpen}
                role={props.role}
                disabled
                open={props.open}
                ref={childRef}
                handleClose={handleClose}
              />
            ) : (
              <UniversityProfile
                setOpen={props.setOpen}
                role={props.role}
                disabled
                ref={childRef}
              />
            )}
          </form>
        </DialogContent>

        <div class="form-group btn-listing">
          {/* <DialogActions>
          <FlatButton
            color="primary"
            onClick={() => {
              childRef.current.getAlert();
              props.setOpen(false);
            }}
            handleClose={handleClose}
            className={profileButton}
          >
            {' '}
            Update
          </FlatButton>
          <FlatButton
            className={profileButton}
            color="primary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {' '}
            Cancel
          </FlatButton>
        </DialogActions> */}
          <button
            className="btn btn-border"
            onClick={() => {
              props.setOpen(false);
            }}
            handleClose={handleClose}
          >
            Cancel
          </button>

          <button
            className="btn btn-fill "
            onClick={() => {
              childRef.current.getAlert();
              props.setOpen(false);
            }}
            handleClose={handleClose}
          >
            Update
          </button>
        </div>
      </Dialog>
    </div>
  );
}
