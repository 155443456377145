import { legacy_createStore as createStore, combineReducers } from 'redux';
import userReducer from './reducers/userReducers';
import tabReducer from './reducers/tabReducer';

const rootReducer = combineReducers({
  user: userReducer,
  tab : tabReducer
});

const store = createStore(rootReducer);

export default store;
